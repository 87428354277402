import Spinner from "react-bootstrap/Spinner";
import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";

export default function Home() {
    const [redirectMessage, setRedirectMessage] = useState("");
    const [redirectTime, setRedirectTime] = useState(3); // 3 seconds
    const {name} = useParams();
    const lowercaseHref = window.location.href.toLowerCase();

    console.log("Current URL:", lowercaseHref);

    useEffect(() => {
        if(name){
            if (name.toString().toLowerCase().includes('dsc')) {
                setRedirectMessage("Redirecting to DSC");
                setTimeout(() => {
                    window.location.href = 'https://www.knaek.nl/intro/Delft/DSC';
                }, 3000); // 3000 milliseconds = 3 seconds
            } else if (name.toString().toLowerCase().includes('rscrvsv')) {
                setRedirectMessage("Redirecting to RSC-RVSV");
                setTimeout(() => {
                    window.location.href = 'https://www.knaek.nl/intro/Rotterdam/RSC-RVSV';
                }, 3000);
            } else {
                setRedirectMessage("Redirecting to Bestellen");
                setTimeout(() => {
                    window.location.href = 'https://www.knaek.nl/bestellen';
                }, 3000);
            }
        }else {
            setRedirectMessage("Redirecting to Bestellen");
            setTimeout(() => {
                window.location.href = 'https://www.knaek.nl/bestellen';
            }, 3000);
        }

    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setRedirectTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="container-fluid vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h3>{redirectMessage}</h3>
                <h5>Redirecting in {redirectTime} seconds...</h5>
                <Spinner className={"mt-5"} animation="grow" style={{ color: "#CA2488", padding: 50 }} />
            </div>
        </div>
    );
}

