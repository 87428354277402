import React, { useEffect, useState } from "react";
import './App.css';
import Home from "./Pages/Home";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

function App() {


    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path="/:name?" component={Home}/>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
